import { formatPrice } from '@/util/price';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

type Props = {
  amount: number;
  subtitle: string;
  icon: React.ReactNode;
  className?: string;
  subtitleClassName?: string;
};

const WinnerCard = ({ amount, subtitle, icon, className, subtitleClassName }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        'flex items-center gap-2 rounded-md bg-primary p-4 text-center text-primary-contrast shadow-[6px_6px_0] !shadow-body-light/40 md:gap-4 rtl:shadow-[-6px_6px_0]',
        className
      )}
    >
      {icon}
      <div className={twMerge('grow text-start text-2xl md:text-4xl', subtitleClassName)}>
        <p dir='ltr' className='whitespace-nowrap font-bold rtl:text-end'>
          {t('common:price', {
            price: formatPrice(amount),
            currency: t('common:USD'),
          })}
        </p>
        <p className='text-xs text-white sm:text-base md:font-semibold'>{subtitle}</p>
      </div>
    </div>
  );
};

export default WinnerCard;
