import { forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const THEME_CLASSES: Record<string, string> = {
  primary: 'focus:ring-primary text-primary group-hover:ring-primary',
  secondary: 'focus:ring-secondary text-secondary group-hover:ring-secondary',
  tertiary: 'focus:ring-tertiary text-tertiary group-hover:ring-tertiary',
  error: 'focus:ring-error text-error group-hover:ring-error',
  success: 'focus:ring-success text-success group-hover:ring-success',
  black: 'focus:ring-black text-black group-hover:ring-black',
  white: 'focus:ring-white text-white group-hover:ring-white',
  transparent: 'focus:ring-transparent text-transparent group-hover:ring-transparent',
};

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string | React.ReactNode;
  error?: string;
  onChange?: (value: boolean, name: string) => void;
  rounded?: boolean;
  groupClassName?: string;
  forceErrorSpace?: boolean;
  name?: string;
  theme?: keyof typeof THEME_CLASSES;
}
const Checkbox = forwardRef<HTMLInputElement, Props>(function Checkbox(
  {
    label,
    error,
    onChange,
    groupClassName,
    theme = 'primary',
    forceErrorSpace = true,
    rounded = true,
    ...props
  },
  ref
) {
  return (
    <>
      <label
        className={`group flex cursor-pointer items-center gap-2 
        ${error ? 'text-error' : ''}
        ${groupClassName || ''}`}
      >
        <input
          type='checkbox'
          ref={ref}
          {...props}
          onChange={(e) => {
            onChange?.(e.target.checked, props?.name || '');
          }}
          className={twMerge(
            'h-[18px] w-[18px] bg-black !ring-offset-body focus:ring-1',
            'focus:ring-offset-1 group-hover:ring-1 group-hover:ring-offset-1',
            THEME_CLASSES[theme] || '',
            rounded ? 'rounded-sm' : '',
            props.className || ''
          )}
        />
        {label}
      </label>
      {error ? (
        <p
          className={`my-1 text-xs text-error
        ${forceErrorSpace ? 'min-h-[16px]' : ''}
        ${error ? 'visible' : 'text-transparent'}`}
        >
          {error}
        </p>
      ) : null}
    </>
  );
});

export default Checkbox;
