import Image from 'next/image';
import WinnerCard from './WinnerCard';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

const ConfettiIcon = (
  <Image
    className='mx-auto shrink-0'
    src='/icons/common/confetti.png'
    width={30}
    height={46}
    alt=''
  />
);

type Props = {
  className?: string;
};
const Rewards = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        'grow space-y-4 rounded-xl bg-secondary p-6 shadow-[7px_7px_0] !shadow-primary-dark rtl:shadow-[-7px_7px_0]',
        className
      )}
    >
      <h3 className='uppercase text-primary'>{t('common:predict.rewards')}</h3>
      <WinnerCard
        amount={100_000}
        subtitle={t('common:predict.first_prize')}
        icon={
          <Image
            className='mx-auto shrink-0'
            src='/icons/common/achievement.png'
            width={41}
            height={60}
            alt=''
          />
        }
      />

      <div className='grid grid-cols-2 gap-4'>
        <WinnerCard
          amount={50_000}
          subtitle={t('common:predict.second_prize')}
          subtitleClassName='text-sm sm:text-lg md:text-xl'
          // className='first-of-type:[&>div>p]:text-base md:first-of-type:[&>div>p]:text-2xl'
          icon={ConfettiIcon}
        />
        <WinnerCard
          amount={25_000}
          subtitle={t('common:predict.third_prize')}
          subtitleClassName='text-sm sm:text-lg md:text-xl'
          // className='first-of-type:[&>div>p]:text-base md:first-of-type:[&>div>p]:text-2xl'
          icon={ConfettiIcon}
        />
      </div>

      <div className='grid grid-cols-2 gap-4'>
        <WinnerCard
          amount={15_000}
          subtitle={t('common:predict.fourth_prize')}
          subtitleClassName='text-sm sm:text-lg md:text-xl'
          // className='first-of-type:[&>div>p]:text-base md:first-of-type:[&>div>p]:text-2xl'
          icon={ConfettiIcon}
        />
        <WinnerCard
          amount={10_000}
          subtitle={t('common:predict.fifth_prize')}
          subtitleClassName='text-sm sm:text-lg md:text-xl'
          // className='first-of-type:[&>div>p]:text-base md:first-of-type:[&>div>p]:text-2xl'
          icon={ConfettiIcon}
        />
      </div>
    </div>
  );
};

export default Rewards;
