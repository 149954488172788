import { useTranslation } from 'next-i18next';
import Rewards from './Rewards';

const HowToParticipate = () => {
  const { t } = useTranslation();

  return (
    <div className='mx-auto w-full max-w-lg space-y-14 lg:max-w-md'>
      <div className='grow space-y-4 rounded-xl bg-secondary p-4 shadow-[7px_7px_0] !shadow-primary-dark sm:p-6 rtl:shadow-[-7px_7px_0]'>
        <h3 className='uppercase text-primary'>{t('common:predict.how_to_participate')}</h3>
        {[1, 2, 3, 4].map((idx) => (
          <div key={idx} className='flex items-center gap-2 text-sm'>
            <p className='min-w-14 shrink-0 rounded-md bg-primary px-4 py-3 text-sm uppercase ltr:font-lemon'>
              {t(`common:predict.step_${idx}`)}
            </p>
            <p className='py-0.5 text-secondary-contrast'>{t(`common:predict.step_${idx}_desc`)}</p>
          </div>
        ))}
      </div>

      <Rewards className='hidden lg:block' />
    </div>
  );
};

export default HowToParticipate;
