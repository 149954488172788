import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const HomeBanner = () => {
  const { t } = useTranslation();

  return (
    <section
      className='relative z-10 -my-48 mb-0 bg-[url(/images/banner.png)] bg-cover bg-top bg-no-repeat before:absolute
        before:inset-0 before:bottom-0 before:-z-10 before:bg-gradient-to-b before:from-transparent before:via-transparent before:to-body lg:bg-[50%_60%] 2xl:bg-[50%_30%]'
    >
      {/* Venue Sides */}
      <p className='absolute left-0 top-[45%] z-10 -translate-x-1/3 -rotate-90 text-xs font-light tracking-[4px] text-secondary lg:text-base'>
        KINGDOM ARENA
      </p>
      <p className='absolute right-0 top-[45%] z-10 translate-x-1/3 rotate-90 text-xs font-light tracking-[4px] text-secondary lg:text-base'>
        KINGDOM ARENA
      </p>

      <div className='container space-y-24 pb-4 pt-72 md:space-y-10 lg:space-y-16'>
        <div className='flex items-start justify-around px-8 sm:justify-between'>
          <Image
            src='/images/ring-of-fire.png'
            className='hidden max-w-14 md:flex lg:max-w-60'
            alt=''
            width={201}
            height={279}
          />
          <Image
            src='/images/fury-usyk.png'
            className='mx-auto max-w-20 md:mx-0 lg:max-w-60'
            alt='logo'
            width={278}
            height={355}
            priority
          />
        </div>

        <div className='space-y-4 px-4 py-6 text-center'>
          <h2 className='text-4xl uppercase text-primary ltr:sm:text-6xl'>
            {t('common:predict.watch_live')}
          </h2>
          <Image
            src='/images/live_channels.png'
            className='mx-auto'
            width={858}
            height={40}
            alt=''
          />
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
