import { forwardRef, InputHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> {
  label?: string | React.ReactNode;
  error?: string;
  onChange?: (value: string) => void;
  onFileChange?: (value: FileList | null) => void;
  rounded?: boolean;
  startIcon?: React.ReactNode;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  hideErrorSpace?: boolean;
  hideErrorMark?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  {
    label,
    error,
    onChange,
    onFileChange,
    rounded = true,
    className,
    startIcon,
    type,
    hideErrorSpace = false,
    hideErrorMark = false,
    ...props
  },
  ref
) {
  const [view, setView] = useState(false);

  return (
    <>
      {label && (
        <label
          className={`block pb-1 font-semibold ${props.disabled ? 'text-gray-400' : ''}`}
          htmlFor={props.id}
        >
          {label}
        </label>
      )}
      <div className='relative'>
        {startIcon && (
          <div className='absolute inset-y-0 h-full w-full max-w-[50px]'>{startIcon}</div>
        )}
        <input
          className={twMerge(
            'w-full border bg-white/5 p-2 text-white placeholder:text-gray-400 hover:bg-white/10 focus:bg-white/5 focus:outline-none focus:ring-0',
            'disabled:cursor-not-allowed disabled:bg-white/10  disabled:text-white/20',
            error ? 'border-error' : 'border-primary/50 focus:border-primary',
            rounded ? 'rounded-lg' : 'rounded-none',
            props.dir === 'ltr' ? 'pr-6' : 'ltr:pr-6 rtl:pl-6 rtl:text-right',
            startIcon ? 'ltr:pl-8 rtl:pr-8' : '',
            className
          )}
          ref={ref}
          onChange={(e) => {
            if (type === 'file') {
              return onFileChange?.(e.target.files);
            }
            onChange?.(e.target.value);
          }}
          type={type === 'password' ? (view ? 'text' : 'password') : type}
          {...props}
        />
        {!!error && !hideErrorMark && (
          <svg
            width='18'
            height='18'
            viewBox='0 0 20 19'
            className={`absolute inset-y-0 right-0 mx-1 h-full fill-error
            ${props.dir === 'ltr' ? '' : 'rtl:left-0 rtl:right-auto'}`}
          >
            <path d='M9.99121 18.7422C14.9746 18.7422 19.0879 14.6289 19.0879 9.6543C19.0879 4.67969 14.9658 0.566406 9.98242 0.566406C5.00781 0.566406 0.90332 4.67969 0.90332 9.6543C0.90332 14.6289 5.0166 18.7422 9.99121 18.7422ZM9.99121 16.9316C5.95703 16.9316 2.73145 13.6885 2.73145 9.6543C2.73145 5.62012 5.95703 2.38574 9.98242 2.38574C14.0166 2.38574 17.2598 5.62012 17.2686 9.6543C17.2773 13.6885 14.0254 16.9316 9.99121 16.9316ZM9.98242 11.1133C10.4658 11.1133 10.7471 10.8408 10.7559 10.3311L10.8877 6.10352C10.9053 5.58496 10.5186 5.20703 9.97363 5.20703C9.42871 5.20703 9.05078 5.57617 9.06836 6.09473L9.19141 10.3311C9.20898 10.832 9.49023 11.1133 9.98242 11.1133ZM9.98242 14.0312C10.5537 14.0312 11.0195 13.6182 11.0195 13.0557C11.0195 12.502 10.5625 12.0889 9.98242 12.0889C9.41113 12.0889 8.94531 12.502 8.94531 13.0557C8.94531 13.6094 9.41992 14.0312 9.98242 14.0312Z' />
          </svg>
        )}
        {type === 'password' && (
          <button
            type='button'
            className={`absolute inset-y-0 mx-1 h-full text-xs text-gray-500 hover:opacity-70 focus:opacity-70
            ${error ? 'ltr:right-6 rtl:left-6' : 'ltr:right-0 rtl:left-0'}`}
            onClick={(e) => {
              e.preventDefault();
              setView((prev) => !prev);
            }}
            tabIndex={-1}
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              className={view ? 'fill-white' : 'fill-zinc-500'}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10 5C4.54545 5 2.5 10 2.5 10C2.5 10 4.54545 15 10 15C14.0909 15 17.5 10 17.5 10C17.5 10 14.0909 5 10 5ZM7.5 10C7.5 11.3807 8.61929 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10Z'
              />
            </svg>
          </button>
        )}
      </div>
      <p
        className={`my-1 text-xs text-error
          ${hideErrorSpace ? '' : 'min-h-[16px]'}
          ${error ? 'visible' : 'text-transparent'}`}
      >
        {error}
      </p>
    </>
  );
});

export default Input;
