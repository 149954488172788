import { forwardRef } from 'react';
import { RadioGroup } from '@headlessui/react';
import Image, { ImageProps } from 'next/image';

type Props = {
  label: string | React.ReactNode;
  options: {
    text: string | React.ReactNode;
    value: string;
    description?: string | React.ReactNode;
    icon?: ImageProps['src'];
  }[];
  value?: string;
  onChange: (value: string) => void;
  name?: string;
  rounded?: boolean;
  error?: string;
  disabled?: boolean;
  className?: string;
  phill?: boolean;
  dark?: boolean;
};

const RadioInput = forwardRef<HTMLInputElement, Props>(function RadioInput(
  {
    name,
    label,
    options,
    value,
    onChange,
    rounded = true,
    error,
    disabled,
    className,
    phill = true,
    dark,
  },
  ref
) {
  return (
    <RadioGroup value={value} onChange={onChange} name={name} disabled={disabled} ref={ref}>
      <RadioGroup.Label
        className={`block pb-1 ${disabled ? 'text-gray-400' : dark ? 'text-text' : ''}`}
      >
        {label}
      </RadioGroup.Label>
      <div className='flex flex-wrap gap-2' ref={ref}>
        {options.map((op) => (
          <RadioGroup.Option
            disabled={disabled}
            key={op.value}
            value={op.value}
            className={({ checked }) =>
              `relative flex grow justify-center border px-5 py-2
              ${
                disabled
                  ? 'cursor-not-allowed border-gray-300 opacity-80'
                  : 'focus:primary-contrast/50 cursor-pointer border-gray-400'
              }
              ${phill ? 'rounded-3xl' : rounded ? 'rounded-lg' : ''}
              ${checked ? 'bg-primary text-primary-contrast shadow-md' : ''}
              ${checked && dark ? 'bg-white' : ''}
              ${checked && !disabled ? 'border-primary-contrast/50' : ''} 
              ${className || ''}`
            }
          >
            {({ checked }) => (
              <>
                {op.icon && (
                  <Image
                    src={op.icon}
                    width={22}
                    height={22}
                    alt={typeof op.text === 'string' ? op.text : op.value}
                    className='pr-4'
                  />
                )}
                <RadioGroup.Label
                  as='div'
                  className={`${checked ? 'text-primary-contrast' : 'text-gray-400'} px-1`}
                >
                  {op.text}
                </RadioGroup.Label>
                {op.description && (
                  <RadioGroup.Description
                    as='div'
                    className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'}`}
                  >
                    {op.description}
                  </RadioGroup.Description>
                )}
                {/* {checked && (
                    <div className="shrink-0 text-white">
                      <CheckIcon className="h-6 w-6" />
                    </div>
                  )} */}
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
      <p
        className={`my-1 min-h-[16px] text-xs text-error
        ${error ? 'visible' : 'text-transparent'}`}
      >
        {error}
      </p>
    </RadioGroup>
  );
});

export default RadioInput;
