import { COUNTRIES, DEFAULT_COUNTRY } from '@/src/constants/countries';
import { Trans, useTranslation } from 'next-i18next';
import { Controller, useForm } from 'react-hook-form';
import RadioInput from '../inputs/Radio';
import { PredictPayload } from '@/api/predict/types';
import MainButton from '../buttons/MainButton';
import Input from '../inputs/Input';
import { emailPattern } from '@/src/constants/pattrens';
import MobileInput from '../inputs/Mobile';
import Checkbox from '../inputs/Checkbox';
import Link from 'next/link';
import Image from 'next/image';
import Rewards from './Rewards';

type FormData = PredictPayload & {
  selected_country: (typeof COUNTRIES)[number];
  agree: boolean;
};

const PredictionSubmit = () => {
  const { t } = useTranslation();

  const {
    register,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      boxer: '',
      round: '1',
      decision: '',
      first_name: '',
      last_name: '',
      email: '',
      selected_country: DEFAULT_COUNTRY,
    },
  });
  const selectedCountry = watch('selected_country');

  return (
    <div className='mx-auto max-w-lg grow space-y-14 lg:max-w-none'>
      <div
        id='make_prediction'
        className='space-y-4 rounded-xl bg-primary p-4 shadow-[7px_7px_0] !shadow-primary-dark sm:p-6 rtl:shadow-[-7px_7px_0]'
      >
        <h3 className='uppercase'>{t('common:predict.submit_prediction')}</h3>

        <form className='relative space-y-4'>
          <div className='rounded-xl bg-[#FFF7E5] px-4 py-6'>
            <div className='flex items-start gap-2'>
              <Image
                src='/icons/common/info.svg'
                className='mx-auto'
                width={24}
                height={24}
                alt=''
              />
              <div className='flex grow flex-col gap-1 text-[#805A00] md:flex-row'>
                <p className='font-semibold'>{`${t('common:predict.submissions_status')}: `}</p>
                <p className='lowercase'>{t('common:predict.submissions_closed')}</p>
              </div>
            </div>
          </div>

          <div className='mx-auto grow space-y-4'>
            <fieldset className='block space-y-4 rounded-xl bg-body px-6 pt-4 text-white'>
              <h4 className='text-2xl rtl:text-xl'>{t('common:predict.which_boxer')}</h4>

              <Controller
                name='boxer'
                control={control}
                rules={{ required: t('common:validation.required') }}
                render={({ field: { ...props } }) => (
                  <RadioInput
                    disabled
                    label=''
                    options={[
                      { value: 'Tyson Fury', text: t('common:predict.tyson_fury') },
                      { value: 'Oleksandr Usyk', text: t('common:predict.oleksandr_usyk') },
                    ]}
                    {...props}
                    error={errors.boxer?.message}
                  />
                )}
              />
            </fieldset>

            <fieldset className='block space-y-4 rounded-xl bg-body px-6 pt-4 text-white'>
              <h4 className='text-2xl rtl:text-xl'>{t('common:predict.which_round')}</h4>

              <Controller
                name='round'
                control={control}
                rules={{ required: t('common:validation.required'), min: 1, max: 12 }}
                render={({ field: { value, onChange, ...props } }) => (
                  <div className='flex items-center justify-evenly gap-6'>
                    <MainButton
                      type='button'
                      shape='text'
                      theme='white'
                      className='mb-6 h-14 w-14 rounded-full enabled:bg-white/5 enabled:hover:bg-white/10 disabled:grayscale disabled:hover:bg-transparent'
                      onClick={() => onChange(Math.max(Number(value) - 1, 1))}
                      disabled={+value === 1}
                    >
                      <Image src='/icons/common/-.svg' width={14} height={4} alt='' />
                    </MainButton>
                    <div>
                      <Input
                        className='max-w-[120px] rounded-full border-none !px-1 py-2 !text-center text-3xl font-black disabled:cursor-default disabled:bg-primary/50 disabled:text-white/50'
                        readOnly
                        disabled
                        {...props}
                        value={+value < 10 ? `0${value}` : value}
                        error={errors.round?.message}
                      />
                    </div>
                    <MainButton
                      type='button'
                      shape='text'
                      theme='white'
                      className='mb-6 h-14 w-14 rounded-full enabled:bg-white/5 enabled:hover:bg-white/10 disabled:grayscale disabled:hover:bg-transparent'
                      onClick={() => onChange(Math.min(Number(value) + 1, 12))}
                      disabled
                    >
                      <Image src='/icons/common/+.svg' width={14} height={14} alt='' />
                    </MainButton>
                  </div>
                )}
              />
            </fieldset>

            <fieldset className='block space-y-4 rounded-xl bg-body px-6 pt-4 text-white'>
              <h4 className='text-2xl rtl:text-xl'>{t('common:predict.decision')}</h4>

              <Controller
                name='decision'
                control={control}
                rules={{ required: t('common:validation.required') }}
                render={({ field: { ...props } }) => (
                  <RadioInput
                    disabled
                    label=''
                    options={[
                      { text: t('common:predict.ko'), value: 'KO' },
                      { text: t('common:predict.unanimous_decision'), value: 'Unanimous' },
                      { text: t('common:predict.split_decision'), value: 'Split' },
                    ]}
                    {...props}
                    error={errors.decision?.message}
                  />
                )}
              />
            </fieldset>
          </div>
          <div className='mx-auto grow rounded-xl bg-body p-4 lg:max-w-none lg:p-6'>
            <fieldset className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              <div>
                <Input
                  disabled
                  id='first_name'
                  label={t('common:auth.first_name')}
                  placeholder={t('common:auth.first_name')}
                  {...register('first_name', {
                    required: t('common:validation.required'),
                    maxLength: {
                      value: 25,
                      message: t('common:validation.max_length', { max: 25 }),
                    },
                  })}
                  onChange={(value) => {
                    setValue('first_name', value);
                  }}
                  error={errors.first_name?.message}
                />
              </div>
              <div>
                <Input
                  disabled
                  id='last_name'
                  label={t('common:auth.last_name')}
                  placeholder={t('common:auth.last_name')}
                  {...register('last_name', {
                    required: t('common:validation.required'),
                    maxLength: {
                      value: 25,
                      message: t('common:validation.max_length', { max: 25 }),
                    },
                  })}
                  onChange={(value) => {
                    setValue('last_name', value);
                  }}
                  error={errors.first_name?.message}
                />
              </div>
            </fieldset>
            <div>
              <Controller
                control={control}
                name='email'
                rules={{
                  required: t('common:validation.required'),
                  pattern: {
                    value: emailPattern,
                    message: t('common:validation.invalid_email'),
                  },
                }}
                defaultValue=''
                render={({ field: { onChange, ...props } }) => (
                  <Input
                    disabled
                    {...props}
                    type='email'
                    label={<span className='font-semibold'>{t('common:auth.email')}</span>}
                    placeholder='you@email.com'
                    onChange={(value) => {
                      onChange(value);
                    }}
                    error={errors.email?.message}
                  />
                )}
              />
            </div>

            <Controller
              name='phone'
              control={control}
              rules={{
                required: t('common:validation.required'),
                validate: (value) => {
                  const hasValidation = selectedCountry?.mobile_starts_with?.length;
                  if (value.includes(' ')) {
                    return t('common:validation.invalid_mobile');
                  }
                  if (hasValidation) {
                    const validStart = selectedCountry?.mobile_starts_with?.some((start) =>
                      value.startsWith(start)
                    );
                    const validLength =
                      value.length >= Number(selectedCountry?.phone_number_lengths?.[0]);
                    return (validStart && validLength) || t('common:validation.invalid_mobile');
                  }
                },
              }}
              render={({ field: { value, ...props } }) => (
                <MobileInput
                  disabled
                  label={t('common:auth.mobile')}
                  {...props}
                  country={selectedCountry}
                  mobile={value || ''}
                  handleChange={(name, value) => {
                    if (name === 'country') {
                      setValue('selected_country', value as FormData['selected_country']);
                    } else {
                      setValue('phone', value as FormData['phone']);
                    }
                  }}
                  error={errors?.phone?.message}
                  placeholder={
                    selectedCountry
                      ? `${selectedCountry?.mobile_starts_with?.[0] || ''}${'x'.repeat(
                          (selectedCountry.phone_number_lengths?.[0] || 7) - 1
                        )}`
                      : ''
                  }
                />
              )}
            />

            <Controller
              name='agree'
              control={control}
              rules={{ required: t('common:validation.required') }}
              defaultValue={false}
              render={({ field: { onChange, value, ...props } }) => (
                <Checkbox
                  {...props}
                  disabled
                  label={
                    <span>
                      <Trans i18nKey='predict.agree' ns='common'>
                        <Link href='/policy' className='text-blue-500 underline'></Link>
                      </Trans>
                    </span>
                  }
                  groupClassName='!items-start text-sm'
                  className='mt-px opacity-50'
                  checked={!value}
                  onChange={(v) => onChange(v)}
                  error={errors.agree?.message}
                />
              )}
            />

            <div className='pt-8'>
              <MainButton
                type='submit'
                className='w-full rounded-2xl py-4 text-xl tracking-wide disabled:bg-primary/50 ltr:font-lemon'
                disabled
              >
                {t('common:predict.submissions_closed')}
              </MainButton>
            </div>
          </div>
        </form>
      </div>
      <Rewards className='lg:hidden' />
    </div>
  );
};

export default PredictionSubmit;
