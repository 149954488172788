// https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${code}.png
// https://wbk-assets.s3.me-south-1.amazonaws.com/flags/h80/${code}.png
export const COUNTRIES = [
  {
    name: 'Saudi Arabia',
    name_ar: 'السعودية',
    code: 'SA',
    dial_code: '966',
    mobile_starts_with: ['5'],
    phone_number_lengths: [9],
  },
  {
    name: 'Afghanistan',
    name_ar: 'أفغانستان',
    code: 'AF',
    dial_code: '93',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Åland Islands',
    name_ar: 'جزر أولاند',
    code: 'AX',
    dial_code: '358',
    mobile_starts_with: ['18'],
    phone_number_lengths: [6, 7, 8],
  },
  {
    name: 'Albania',
    name_ar: 'ألبانيا',
    code: 'AL',
    dial_code: '355',
    mobile_starts_with: ['6'],
    phone_number_lengths: [9],
  },
  {
    name: 'Algeria',
    name_ar: 'الجزائر',
    code: 'DZ',
    dial_code: '213',
    mobile_starts_with: ['5', '6', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'American Samoa',
    name_ar: 'ساموا الأمريكية',
    code: 'AS',
    dial_code: '1684',
    mobile_starts_with: ['684733', '684258'],
    phone_number_lengths: [10],
  },
  {
    name: 'Andorra',
    name_ar: 'أندورا',
    code: 'AD',
    dial_code: '376',
    mobile_starts_with: ['3', '4', '6'],
    phone_number_lengths: [6],
  },
  {
    name: 'Angola',
    name_ar: 'أنغولا',
    code: 'AO',
    dial_code: '244',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Anguilla',
    name_ar: 'أنغويلا',
    code: 'AI',
    dial_code: '1264',
    mobile_starts_with: ['2645', '2647'],
    phone_number_lengths: [10],
  },
  {
    name: 'Antigua and Barbuda',
    name_ar: 'أنتيغوا وباربودا',
    code: 'AG',
    dial_code: '1268',
    mobile_starts_with: ['2687'],
    phone_number_lengths: [10],
  },
  {
    name: 'Argentina',
    name_ar: 'الأرجنتين',
    code: 'AR',
    dial_code: '54',
    mobile_starts_with: ['1', '2', '3'],
    phone_number_lengths: [8, 9, 10, 11, 12],
  },
  {
    name: 'Armenia',
    name_ar: 'أرمينيا',
    code: 'AM',
    dial_code: '374',
    mobile_starts_with: ['3', '4', '5', '7', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Aruba',
    name_ar: 'أروبا',
    code: 'AW',
    dial_code: '297',
    mobile_starts_with: ['5', '6', '7', '9'],
    phone_number_lengths: [7],
  },
  {
    name: 'Australia',
    name_ar: 'أستراليا',
    code: 'AU',
    dial_code: '61',
    mobile_starts_with: ['4'],
    phone_number_lengths: [9],
  },
  {
    name: 'Austria',
    name_ar: 'النمسا',
    code: 'AT',
    dial_code: '43',
    mobile_starts_with: ['6'],
    phone_number_lengths: [10, 11, 12, 13, 14],
  },
  {
    name: 'Azerbaijan',
    name_ar: 'أذربيجان',
    code: 'AZ',
    dial_code: '994',
    mobile_starts_with: ['4', '5', '6', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Bahamas',
    name_ar: 'باهاماس',
    code: 'BS',
    dial_code: '1242',
    mobile_starts_with: ['242'],
    phone_number_lengths: [10],
  },
  {
    name: 'Bahrain',
    name_ar: 'البحرين',
    code: 'BH',
    dial_code: '973',
    mobile_starts_with: ['3'],
    phone_number_lengths: [8],
  },
  {
    name: 'Bangladesh',
    name_ar: 'بنغلاديش',
    code: 'BD',
    dial_code: '880',
    mobile_starts_with: ['1'],
    phone_number_lengths: [8, 9, 10],
  },
  {
    name: 'Barbados',
    name_ar: 'باربادوس',
    code: 'BB',
    dial_code: '1246',
    mobile_starts_with: ['246'],
    phone_number_lengths: [10],
  },
  {
    name: 'Belarus',
    name_ar: 'بيلاروس',
    code: 'BY',
    dial_code: '375',
    mobile_starts_with: ['25', '29', '33', '44'],
    phone_number_lengths: [9],
  },
  {
    name: 'Belgium',
    name_ar: 'بلجيكا',
    code: 'BE',
    dial_code: '32',
    mobile_starts_with: ['4', '3'],
    phone_number_lengths: [9, 8],
  },
  {
    name: 'Belize',
    name_ar: 'بليز',
    code: 'BZ',
    dial_code: '501',
    mobile_starts_with: ['6'],
    phone_number_lengths: [7],
  },
  {
    name: 'Benin',
    name_ar: 'بنين',
    code: 'BJ',
    dial_code: '229',
    mobile_starts_with: ['4', '6', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Bermuda',
    name_ar: 'برمودا',
    code: 'BM',
    dial_code: '1441',
    mobile_starts_with: ['4413', '4415', '4417'],
    phone_number_lengths: [10],
  },
  {
    name: 'Bhutan',
    name_ar: 'بوتان',
    code: 'BT',
    dial_code: '975',
    mobile_starts_with: ['17'],
    phone_number_lengths: [8],
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    name_ar: 'بوليفيا',
    code: 'BO',
    dial_code: '591',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Bosnia and Herzegovina',
    name_ar: 'البوسنة والهرسك',
    code: 'BA',
    dial_code: '387',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Botswana',
    name_ar: 'بوتسوانا',
    code: 'BW',
    dial_code: '267',
    mobile_starts_with: ['71', '72', '73', '74', '75', '76'],
    phone_number_lengths: [8],
  },
  {
    name: 'Bouvet Island',
    name_ar: 'جزيرة بوفيه',
    code: 'BV',
    dial_code: '47',
  },
  {
    name: 'Brazil',
    name_ar: 'البرازيل',
    code: 'BR',
    dial_code: '55',
    mobile_starts_with: [
      '119',
      '129',
      '139',
      '149',
      '159',
      '169',
      '179',
      '189',
      '199',
      '219',
      '229',
      '249',
      '279',
      '289',
      '319',
      '329',
      '339',
      '349',
      '359',
      '379',
      '389',
      '419',
      '429',
      '439',
      '449',
      '459',
      '469',
      '479',
      '489',
      '499',
      '519',
      '539',
      '549',
      '559',
      '619',
      '629',
      '639',
      '649',
      '659',
      '669',
      '679',
      '689',
      '699',
      '719',
      '739',
      '749',
      '759',
      '779',
      '799',
      '819',
      '829',
      '839',
      '849',
      '859',
      '869',
      '879',
      '889',
      '899',
      '919',
      '929',
      '939',
      '949',
      '959',
      '969',
      '979',
      '989',
      '999',
    ],
    phone_number_lengths: [10, 11],
  },
  {
    name: 'British Indian Ocean Territory',
    name_ar: 'إقليم المحيط الهندي البريطاني',
    code: 'IO',
    dial_code: '246',
  },
  {
    name: 'Brunei Darussalam',
    name_ar: 'بروناي',
    code: 'BN',
    dial_code: '673',
    mobile_starts_with: ['7', '8'],
    phone_number_lengths: [7],
  },
  {
    name: 'Bulgaria',
    name_ar: 'بلغاريا',
    code: 'BG',
    dial_code: '359',
    mobile_starts_with: ['87', '88', '89', '98', '99', '43'],
    phone_number_lengths: [8, 9],
  },
  {
    name: 'Burkina Faso',
    name_ar: 'بوركينا فاسو',
    code: 'BF',
    dial_code: '226',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Burundi',
    name_ar: 'بوروندي',
    code: 'BI',
    dial_code: '257',
    mobile_starts_with: ['7', '29'],
    phone_number_lengths: [8],
  },
  {
    name: 'Cambodia',
    name_ar: 'كمبوديا',
    code: 'KH',
    dial_code: '855',
    mobile_starts_with: ['1', '6', '7', '8', '9'],
    phone_number_lengths: [8, 9],
  },
  {
    name: 'Cameroon',
    name_ar: 'الكاميرون',
    code: 'CM',
    dial_code: '237',
    mobile_starts_with: ['6'],
    phone_number_lengths: [9],
  },
  {
    name: 'Canada',
    name_ar: 'كندا',
    code: 'CA',
    dial_code: '1',
    mobile_starts_with: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '367',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '600',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '778',
      '780',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
    phone_number_lengths: [10],
  },
  {
    name: 'Cape Verde',
    name_ar: 'الرأس الأخضر',
    code: 'CV',
    dial_code: '238',
    mobile_starts_with: ['5', '9'],
    phone_number_lengths: [7],
  },
  {
    name: 'Cayman Islands',
    name_ar: 'جزر كايمان',
    code: 'KY',
    dial_code: '345',
    mobile_starts_with: ['345'],
    phone_number_lengths: [10],
  },
  {
    name: 'Central African Republic',
    name_ar: 'جمهورية أفريقيا الوسطى',
    code: 'CF',
    dial_code: '236',
    mobile_starts_with: ['7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Chad',
    name_ar: 'تشاد',
    code: 'TD',
    dial_code: '235',
    mobile_starts_with: ['6', '7', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Chile',
    name_ar: 'تشيلي',
    code: 'CL',
    dial_code: '56',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'China',
    name_ar: 'الصين',
    code: 'CN',
    dial_code: '86',
    mobile_starts_with: ['13', '14', '15', '17', '18', '19', '16'],
    phone_number_lengths: [11],
  },
  {
    name: 'Christmas Island',
    name_ar: 'جزيرة كريسماس',
    code: 'CX',
    dial_code: '61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    name_ar: 'جزر كوكوس',
    code: 'CC',
    dial_code: '61',
  },
  {
    name: 'Colombia',
    name_ar: 'كولومبيا',
    code: 'CO',
    dial_code: '57',
    mobile_starts_with: ['3'],
    phone_number_lengths: [10],
  },
  {
    name: 'Comoros',
    name_ar: 'جزر القمر',
    code: 'KM',
    dial_code: '269',
    mobile_starts_with: ['3', '76'],
    phone_number_lengths: [7],
  },
  {
    name: 'Congo',
    name_ar: 'جمهورية الكونغو',
    code: 'CG',
    dial_code: '242',
    mobile_starts_with: ['0'],
    phone_number_lengths: [9],
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    name_ar: 'جمهورية الكونغو الديمقراطية',
    code: 'CD',
    dial_code: '243',
    mobile_starts_with: ['8', '9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Cook Islands',
    name_ar: 'جزر كوك',
    code: 'CK',
    dial_code: '682',
    mobile_starts_with: ['5', '7'],
    phone_number_lengths: [5],
  },
  {
    name: 'Costa Rica',
    name_ar: 'كوستاريكا',
    code: 'CR',
    dial_code: '506',
    mobile_starts_with: ['5', '6', '7', '8'],
    phone_number_lengths: [8],
  },
  {
    name: "Cote d'Ivoire",
    name_ar: 'ساحل العاج',
    code: 'CI',
    dial_code: '225',
    mobile_starts_with: ['0', '4', '5', '6', '7', '8'],
    phone_number_lengths: [10],
  },
  {
    name: 'Croatia',
    name_ar: 'كرواتيا',
    code: 'HR',
    dial_code: '385',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8, 9],
  },
  {
    name: 'Cuba',
    name_ar: 'كوبا',
    code: 'CU',
    dial_code: '53',
    mobile_starts_with: ['5'],
    phone_number_lengths: [8],
  },
  {
    name: 'Cyprus',
    name_ar: 'قبرص',
    code: 'CY',
    dial_code: '357',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Czech Republic',
    name_ar: 'جمهورية التشيك',
    code: 'CZ',
    dial_code: '420',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Denmark',
    name_ar: 'الدنمارك',
    code: 'DK',
    dial_code: '45',
    mobile_starts_with: [
      '2',
      '30',
      '31',
      '40',
      '41',
      '42',
      '50',
      '51',
      '52',
      '53',
      '60',
      '61',
      '71',
      '81',
      '91',
      '92',
      '93',
    ],
    phone_number_lengths: [8],
  },
  {
    name: 'Djibouti',
    name_ar: 'جيبوتي',
    code: 'DJ',
    dial_code: '253',
    mobile_starts_with: ['77'],
    phone_number_lengths: [8],
  },
  {
    name: 'Dominica',
    name_ar: 'دومينيكا',
    code: 'DM',
    dial_code: '1767',
    mobile_starts_with: ['767'],
    phone_number_lengths: [10],
  },
  {
    name: 'Dominican Republic',
    name_ar: 'جمهورية الدومينيكان',
    code: 'DO',
    dial_code: '1849',
    mobile_starts_with: ['809', '829', '849'],
    phone_number_lengths: [10],
  },
  {
    name: 'Ecuador',
    name_ar: 'الإكوادور',
    code: 'EC',
    dial_code: '593',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Egypt',
    name_ar: 'مصر',
    code: 'EG',
    dial_code: '20',
    mobile_starts_with: ['1'],
    phone_number_lengths: [8, 10],
  },
  {
    name: 'El Salvador',
    name_ar: 'السلفادور',
    code: 'SV',
    dial_code: '503',
    mobile_starts_with: ['7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Equatorial Guinea',
    name_ar: 'غينيا الاستوائية',
    code: 'GQ',
    dial_code: '240',
    mobile_starts_with: ['222', '551'],
    phone_number_lengths: [9],
  },
  {
    name: 'Eritrea',
    name_ar: 'إريتريا',
    code: 'ER',
    dial_code: '291',
    mobile_starts_with: ['1', '7', '8'],
    phone_number_lengths: [7],
  },
  {
    name: 'Estonia',
    name_ar: 'إستونيا',
    code: 'EE',
    dial_code: '372',
    mobile_starts_with: ['5', '81', '82', '83'],
    phone_number_lengths: [7, 8],
  },
  {
    name: 'Ethiopia',
    name_ar: 'إثيوبيا',
    code: 'ET',
    dial_code: '251',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Falkland Islands (Malvinas)',
    name_ar: 'جزر فوكلاند',
    code: 'FK',
    dial_code: '500',
    mobile_starts_with: ['5', '6'],
    phone_number_lengths: [5],
  },
  {
    name: 'Faroe Islands',
    name_ar: 'جزر فارو',
    code: 'FO',
    dial_code: '298',
    mobile_starts_with: [],
    phone_number_lengths: [6],
  },
  {
    name: 'Fiji',
    name_ar: 'فيجي',
    code: 'FJ',
    dial_code: '679',
    mobile_starts_with: ['2', '7', '8', '9'],
    phone_number_lengths: [7],
  },
  {
    name: 'Finland',
    name_ar: 'فنلندا',
    code: 'FI',
    dial_code: '358',
    mobile_starts_with: ['4', '5'],
    phone_number_lengths: [9, 10],
  },
  {
    name: 'France',
    name_ar: 'فرنسا',
    code: 'FR',
    dial_code: '33',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'French Guiana',
    name_ar: 'غويانا الفرنسية',
    code: 'GF',
    dial_code: '594',
    mobile_starts_with: ['694'],
    phone_number_lengths: [9],
  },
  {
    name: 'French Polynesia',
    name_ar: 'بولينزيا الفرنسية',
    code: 'PF',
    dial_code: '689',
    mobile_starts_with: ['8'],
    phone_number_lengths: [8],
  },
  {
    name: 'French Southern Territories',
    name_ar: 'أراض فرنسية جنوبية وأنتارتيكية',
    code: 'TF',
    dial_code: '262',
  },
  {
    name: 'Gabon',
    name_ar: 'الغابون',
    code: 'GA',
    dial_code: '241',
    mobile_starts_with: ['2', '3', '4', '5', '6', '7'],
    phone_number_lengths: [7],
  },
  {
    name: 'Gambia',
    name_ar: 'غامبيا',
    code: 'GM',
    dial_code: '220',
    mobile_starts_with: ['7', '9'],
    phone_number_lengths: [7],
  },
  {
    name: 'Georgia',
    name_ar: 'جورجيا',
    code: 'GE',
    dial_code: '995',
    mobile_starts_with: ['5', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Germany',
    name_ar: 'ألمانيا',
    code: 'DE',
    dial_code: '49',
    mobile_starts_with: ['15', '16', '17'],
    phone_number_lengths: [10, 11],
  },
  {
    name: 'Ghana',
    name_ar: 'غانا',
    code: 'GH',
    dial_code: '233',
    mobile_starts_with: ['2', '5'],
    phone_number_lengths: [9],
  },
  {
    name: 'Gibraltar',
    name_ar: 'جبل طارق',
    code: 'GI',
    dial_code: '350',
    mobile_starts_with: ['5'],
    phone_number_lengths: [8],
  },
  {
    name: 'Greece',
    name_ar: 'اليونان',
    code: 'GR',
    dial_code: '30',
    mobile_starts_with: ['6'],
    phone_number_lengths: [10],
  },
  {
    name: 'Greenland',
    name_ar: 'جرينلاند',
    code: 'GL',
    dial_code: '299',
    mobile_starts_with: ['2', '4', '5'],
    phone_number_lengths: [6],
  },
  {
    name: 'Grenada',
    name_ar: 'غرينادا',
    code: 'GD',
    dial_code: '1473',
    mobile_starts_with: ['473'],
    phone_number_lengths: [10],
  },
  {
    name: 'Guam',
    name_ar: 'غوام',
    code: 'GU',
    dial_code: '1671',
    mobile_starts_with: ['671'],
    phone_number_lengths: [10],
  },
  {
    name: 'Guatemala',
    name_ar: 'غواتيمالا',
    code: 'GT',
    dial_code: '502',
    mobile_starts_with: ['3', '4', '5'],
    phone_number_lengths: [8],
  },
  {
    name: 'Guernsey',
    name_ar: 'غيرنزي',
    code: 'GG',
    dial_code: '44',
  },
  {
    name: 'Guinea',
    name_ar: 'غينيا',
    code: 'GN',
    dial_code: '224',
    mobile_starts_with: ['6'],
    phone_number_lengths: [9],
  },
  {
    name: 'Guinea-Bissau',
    name_ar: 'غينيا بيساو',
    code: 'GW',
    dial_code: '245',
    mobile_starts_with: ['5', '6', '7'],
    phone_number_lengths: [7],
  },
  {
    name: 'Guyana',
    name_ar: 'غيانا',
    code: 'GY',
    dial_code: '592',
    mobile_starts_with: ['6'],
    phone_number_lengths: [7],
  },
  {
    name: 'Haiti',
    name_ar: 'هايتي',
    code: 'HT',
    dial_code: '509',
    mobile_starts_with: ['3', '4'],
    phone_number_lengths: [8],
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    name_ar: 'جزيرة هيرد وجزر ماكدونالد',
    code: 'HM',
    dial_code: '672',
  },
  {
    name: 'Holy See (Vatican City State)',
    name_ar: 'الفاتيكان',
    code: 'VA',
    dial_code: '379',
  },
  {
    name: 'Honduras',
    name_ar: 'هندوراس',
    code: 'HN',
    dial_code: '504',
    mobile_starts_with: ['3', '7', '8', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Hong Kong',
    name_ar: 'هونغ كونغ',
    code: 'HK',
    dial_code: '852',
    mobile_starts_with: [
      '4',
      '5',
      '6',
      '70',
      '71',
      '72',
      '73',
      '81',
      '82',
      '83',
      '84',
      '85',
      '86',
      '87',
      '88',
      '89',
      '9',
    ],
    phone_number_lengths: [8],
  },
  {
    name: 'Hungary',
    name_ar: 'المجر',
    code: 'HU',
    dial_code: '36',
    mobile_starts_with: ['20', '30', '31', '50', '70'],
    phone_number_lengths: [9],
  },
  {
    name: 'Iceland',
    name_ar: 'آيسلندا',
    code: 'IS',
    dial_code: '354',
    mobile_starts_with: ['6', '7', '8'],
    phone_number_lengths: [7],
  },
  {
    name: 'India',
    name_ar: 'الهند',
    code: 'IN',
    dial_code: '91',
    mobile_starts_with: ['6', '7', '8', '9'],
    phone_number_lengths: [10],
  },
  {
    name: 'Indonesia',
    name_ar: 'إندونيسيا',
    code: 'ID',
    dial_code: '62',
    mobile_starts_with: ['8'],
    phone_number_lengths: [9, 10, 11, 12],
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    name_ar: 'إيران',
    code: 'IR',
    dial_code: '98',
    mobile_starts_with: ['9'],
    phone_number_lengths: [10],
  },
  {
    name: 'Iraq',
    name_ar: 'العراق',
    code: 'IQ',
    dial_code: '964',
    mobile_starts_with: ['7'],
    phone_number_lengths: [10],
  },
  {
    name: 'Ireland',
    name_ar: 'أيرلندا',
    code: 'IE',
    dial_code: '353',
    mobile_starts_with: ['82', '83', '84', '85', '86', '87', '88', '89'],
    phone_number_lengths: [9],
  },
  {
    name: 'Isle of Man',
    name_ar: 'جزيرة مان',
    code: 'IM',
    dial_code: '44',
  },
  // {
  //   name: 'Palestinian Territory, Occupied',
  //   name_ar: 'فلسطين المحتلة',
  //   code: 'IL',
  //   dial_code: '972',
  //   mobile_starts_with: ['5'],
  //   phone_number_lengths: [9],
  // },
  {
    name: 'Italy',
    name_ar: 'إيطاليا',
    code: 'IT',
    dial_code: '39',
    mobile_starts_with: ['3'],
    phone_number_lengths: [9, 10],
  },
  {
    name: 'Jamaica',
    name_ar: 'جامايكا',
    code: 'JM',
    dial_code: '1876',
    mobile_starts_with: ['876'],
    phone_number_lengths: [10],
  },
  {
    name: 'Japan',
    name_ar: 'اليابان',
    code: 'JP',
    dial_code: '81',
    mobile_starts_with: ['70', '80', '90'],
    phone_number_lengths: [10],
  },
  {
    name: 'Jersey',
    name_ar: 'جيرزي',
    code: 'JE',
    dial_code: '44',
  },
  {
    name: 'Jordan',
    name_ar: 'الأردن',
    code: 'JO',
    dial_code: '962',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Kazakhstan',
    name_ar: 'كازاخستان',
    code: 'KZ',
    dial_code: '7',
    mobile_starts_with: ['70', '74', '77'],
    phone_number_lengths: [10],
  },
  {
    name: 'Kenya',
    name_ar: 'كينيا',
    code: 'KE',
    dial_code: '254',
    mobile_starts_with: ['7', '1'],
    phone_number_lengths: [9],
  },
  {
    name: 'Kiribati',
    name_ar: 'كيريباتي',
    code: 'KI',
    dial_code: '686',
    mobile_starts_with: ['9', '30'],
    phone_number_lengths: [5],
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    name_ar: 'كوريا الشمالية',
    code: 'KP',
    dial_code: '850',
  },
  {
    name: 'Korea, Republic of South Korea',
    name_ar: 'كوريا الجنوبية',
    code: 'KR',
    dial_code: '82',
    mobile_starts_with: ['1'],
    phone_number_lengths: [9, 10],
  },
  {
    name: 'Kuwait',
    name_ar: 'الكويت',
    code: 'KW',
    dial_code: '965',
    mobile_starts_with: ['5', '6', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Kyrgyzstan',
    name_ar: 'قيرغيزستان',
    code: 'KG',
    dial_code: '996',
    mobile_starts_with: ['5', '7', '8', '9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Laos',
    name_ar: 'لاوس',
    code: 'LA',
    dial_code: '856',
    mobile_starts_with: ['20'],
    phone_number_lengths: [10],
  },
  {
    name: 'Latvia',
    name_ar: 'لاتفيا',
    code: 'LV',
    dial_code: '371',
    mobile_starts_with: ['2'],
    phone_number_lengths: [8],
  },
  {
    name: 'Lebanon',
    name_ar: 'لبنان',
    code: 'LB',
    dial_code: '961',
    mobile_starts_with: ['3', '7', '8'],
    phone_number_lengths: [7, 8],
  },
  {
    name: 'Lesotho',
    name_ar: 'ليسوتو',
    code: 'LS',
    dial_code: '266',
    mobile_starts_with: ['5', '6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Liberia',
    name_ar: 'ليبيريا',
    code: 'LR',
    dial_code: '231',
    mobile_starts_with: ['4', '5', '6', '7'],
    phone_number_lengths: [7, 8],
  },
  {
    name: 'Libyan Arab Jamahiriya',
    name_ar: 'ليبيا',
    code: 'LY',
    dial_code: '218',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Liechtenstein',
    name_ar: 'ليختنشتاين',
    code: 'LI',
    dial_code: '423',
    mobile_starts_with: ['7'],
    phone_number_lengths: [7],
  },
  {
    name: 'Lithuania',
    name_ar: 'ليتوانيا',
    code: 'LT',
    dial_code: '370',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Luxembourg',
    name_ar: 'لوكسمبورغ',
    code: 'LU',
    dial_code: '352',
    mobile_starts_with: ['6'],
    phone_number_lengths: [9],
  },
  {
    name: 'Macao',
    name_ar: 'ماكاو',
    code: 'MO',
    dial_code: '853',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Macedonia',
    name_ar: 'مقدونيا',
    code: 'MK',
    dial_code: '389',
    mobile_starts_with: ['7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Madagascar',
    name_ar: 'مدغشقر',
    code: 'MG',
    dial_code: '261',
    mobile_starts_with: ['3'],
    phone_number_lengths: [9],
  },
  {
    name: 'Malawi',
    name_ar: 'مالاوي',
    code: 'MW',
    dial_code: '265',
    mobile_starts_with: ['77', '88', '99'],
    phone_number_lengths: [9],
  },
  {
    name: 'Malaysia',
    name_ar: 'ماليزيا',
    code: 'MY',
    dial_code: '60',
    mobile_starts_with: ['1', '6'],
    phone_number_lengths: [8, 9, 10],
  },
  {
    name: 'Maldives',
    name_ar: 'جزر المالديف',
    code: 'MV',
    dial_code: '960',
    mobile_starts_with: ['7', '9'],
    phone_number_lengths: [7],
  },
  {
    name: 'Mali',
    name_ar: 'مالي',
    code: 'ML',
    dial_code: '223',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Malta',
    name_ar: 'مالطا',
    code: 'MT',
    dial_code: '356',
    mobile_starts_with: ['7', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Marshall Islands',
    name_ar: 'جزر مارشال',
    code: 'MH',
    dial_code: '692',
    mobile_starts_with: [],
    phone_number_lengths: [7],
  },
  {
    name: 'Martinique',
    name_ar: 'مارتينيك',
    code: 'MQ',
    dial_code: '596',
    mobile_starts_with: ['696'],
    phone_number_lengths: [9],
  },
  {
    name: 'Mauritania',
    name_ar: 'موريتانيا',
    code: 'MR',
    dial_code: '222',
    mobile_starts_with: [],
    phone_number_lengths: [8],
  },
  {
    name: 'Mauritius',
    name_ar: 'موريشيوس',
    code: 'MU',
    dial_code: '230',
    mobile_starts_with: ['5'],
    phone_number_lengths: [8],
  },
  {
    name: 'Mayotte',
    name_ar: 'مايوت',
    code: 'YT',
    dial_code: '262',
    mobile_starts_with: ['639'],
    phone_number_lengths: [9],
  },
  {
    name: 'Mexico',
    name_ar: 'المكسيك',
    code: 'MX',
    dial_code: '52',
    mobile_starts_with: [''],
    phone_number_lengths: [10, 11],
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    name_ar: 'ولايات ميكرونيسيا المتحدة',
    code: 'FM',
    dial_code: '691',
    mobile_starts_with: [],
    phone_number_lengths: [7],
  },
  {
    name: 'Moldova',
    name_ar: 'مولدوفا',
    code: 'MD',
    dial_code: '373',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Monaco',
    name_ar: 'موناكو',
    code: 'MC',
    dial_code: '377',
    mobile_starts_with: ['4', '6'],
    phone_number_lengths: [8, 9],
  },
  {
    name: 'Mongolia',
    name_ar: 'منغوليا',
    code: 'MN',
    dial_code: '976',
    mobile_starts_with: ['5', '8', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Montenegro',
    name_ar: 'الجبل الأسود',
    code: 'ME',
    dial_code: '382',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Montserrat',
    name_ar: 'مونتسرات',
    code: 'MS',
    dial_code: '1664',
    mobile_starts_with: ['664'],
    phone_number_lengths: [10],
  },
  {
    name: 'Morocco',
    name_ar: 'المغرب',
    code: 'MA',
    dial_code: '212',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Mozambique',
    name_ar: 'موزمبيق',
    code: 'MZ',
    dial_code: '258',
    mobile_starts_with: ['8'],
    phone_number_lengths: [9],
  },
  {
    name: 'Myanmar',
    name_ar: 'ميانمار',
    code: 'MM',
    dial_code: '95',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8, 9, 10],
  },
  {
    name: 'Namibia',
    name_ar: 'ناميبيا',
    code: 'NA',
    dial_code: '264',
    mobile_starts_with: ['60', '81', '82', '85'],
    phone_number_lengths: [9],
  },
  {
    name: 'Nauru',
    name_ar: 'ناورو',
    code: 'NR',
    dial_code: '674',
    mobile_starts_with: ['555'],
    phone_number_lengths: [7],
  },
  {
    name: 'Nepal',
    name_ar: 'نيبال',
    code: 'NP',
    dial_code: '977',
    mobile_starts_with: ['97', '98'],
    phone_number_lengths: [10],
  },
  {
    name: 'Netherlands',
    name_ar: 'هولندا',
    code: 'NL',
    dial_code: '31',
    mobile_starts_with: ['6'],
    phone_number_lengths: [9],
  },
  {
    name: 'New Caledonia',
    name_ar: 'كاليدونيا الجديدة',
    code: 'NC',
    dial_code: '687',
    mobile_starts_with: ['7', '8', '9'],
    phone_number_lengths: [6],
  },
  {
    name: 'New Zealand',
    name_ar: 'نيوزيلندا',
    code: 'NZ',
    dial_code: '64',
    mobile_starts_with: ['2'],
    phone_number_lengths: [8, 9, 10],
  },
  {
    name: 'Nicaragua',
    name_ar: 'نيكاراغوا',
    code: 'NI',
    dial_code: '505',
    mobile_starts_with: ['8'],
    phone_number_lengths: [8],
  },
  {
    name: 'Niger',
    name_ar: 'النيجر',
    code: 'NE',
    dial_code: '227',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Nigeria',
    name_ar: 'نيجيريا',
    code: 'NG',
    dial_code: '234',
    mobile_starts_with: ['70', '80', '81', '90', '91'],
    phone_number_lengths: [10],
  },
  {
    name: 'Niue',
    name_ar: 'نييوي',
    code: 'NU',
    dial_code: '683',
    mobile_starts_with: [],
    phone_number_lengths: [4],
  },
  {
    name: 'Norfolk Island',
    name_ar: 'جزيرة نورفولك',
    code: 'NF',
    dial_code: '672',
    mobile_starts_with: ['5', '8'],
    phone_number_lengths: [5],
  },
  {
    name: 'Northern Mariana Islands',
    name_ar: 'جزر ماريانا الشمالية',
    code: 'MP',
    dial_code: '1670',
    mobile_starts_with: ['670'],
    phone_number_lengths: [10],
  },
  {
    name: 'Norway',
    name_ar: 'النرويج',
    code: 'NO',
    dial_code: '47',
    mobile_starts_with: ['4', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Oman',
    name_ar: 'عمان',
    code: 'OM',
    dial_code: '968',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Pakistan',
    name_ar: 'باكستان',
    code: 'PK',
    dial_code: '92',
    mobile_starts_with: ['3'],
    phone_number_lengths: [10],
  },
  {
    name: 'Palau',
    name_ar: 'بالاو',
    code: 'PW',
    dial_code: '680',
    mobile_starts_with: [],
    phone_number_lengths: [7],
  },
  {
    name: 'Palestinian',
    name_ar: 'فلسطين',
    code: 'PS',
    dial_code: '970',
    mobile_starts_with: ['5'],
    phone_number_lengths: [9],
  },
  {
    name: 'Panama',
    name_ar: 'بنما',
    code: 'PA',
    dial_code: '507',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Papua New Guinea',
    name_ar: 'بابوا غينيا الجديدة',
    code: 'PG',
    dial_code: '675',
    mobile_starts_with: ['7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Paraguay',
    name_ar: 'باراغواي',
    code: 'PY',
    dial_code: '595',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Peru',
    name_ar: 'بيرو',
    code: 'PE',
    dial_code: '51',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Philippines',
    name_ar: 'الفلبين',
    code: 'PH',
    dial_code: '63',
    mobile_starts_with: ['9'],
    phone_number_lengths: [10],
  },
  {
    name: 'Pitcairn',
    name_ar: 'جزر بيتكيرن',
    code: 'PN',
    dial_code: '64',
  },
  {
    name: 'Poland',
    name_ar: 'بولندا',
    code: 'PL',
    dial_code: '48',
    mobile_starts_with: ['4', '5', '6', '7', '8'],
    phone_number_lengths: [9],
  },
  {
    name: 'Portugal',
    name_ar: 'البرتغال',
    code: 'PT',
    dial_code: '351',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Puerto Rico',
    name_ar: 'بورتوريكو',
    code: 'PR',
    dial_code: '1939',
    mobile_starts_with: ['787', '939'],
    phone_number_lengths: [10],
  },
  {
    name: 'Qatar',
    name_ar: 'قطر',
    code: 'QA',
    dial_code: '974',
    mobile_starts_with: ['3', '5', '6', '7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Romania',
    name_ar: 'رومانيا',
    code: 'RO',
    dial_code: '40',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Russia',
    name_ar: 'روسيا',
    code: 'RU',
    dial_code: '7',
    mobile_starts_with: ['9', '495', '498', '499'],
    phone_number_lengths: [10],
  },
  {
    name: 'Rwanda',
    name_ar: 'رواندا',
    code: 'RW',
    dial_code: '250',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Reunion',
    name_ar: 'لا ريونيون',
    code: 'RE',
    dial_code: '262',
    mobile_starts_with: ['692', '693'],
    phone_number_lengths: [9],
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    name_ar: 'سانت هيلانة وأسينشين وتريستان دا كونا',
    code: 'SH',
    dial_code: '290',
    mobile_starts_with: [],
    phone_number_lengths: [4],
  },
  {
    name: 'Saint Kitts and Nevis',
    name_ar: 'سانت كيتس ونيفيس',
    code: 'KN',
    dial_code: '1869',
    mobile_starts_with: ['869'],
    phone_number_lengths: [10],
  },
  {
    name: 'Saint Lucia',
    name_ar: 'سانت لوسيا',
    code: 'LC',
    dial_code: '1758',
    mobile_starts_with: ['758'],
    phone_number_lengths: [10],
  },
  {
    name: 'Saint Martin',
    name_ar: 'تجمع سان مارتين',
    code: 'MF',
    dial_code: '590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    name_ar: 'سان بيير وميكلون',
    code: 'PM',
    dial_code: '508',
    mobile_starts_with: ['55', '41'],
    phone_number_lengths: [6],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    name_ar: 'سانت فينسنت والغرينادين',
    code: 'VC',
    dial_code: '1784',
    mobile_starts_with: ['784'],
    phone_number_lengths: [10],
  },
  {
    name: 'Samoa',
    name_ar: 'ساموا',
    code: 'WS',
    dial_code: '685',
    mobile_starts_with: ['7'],
    phone_number_lengths: [7],
  },
  {
    name: 'San Marino',
    name_ar: 'سان مارينو',
    code: 'SM',
    dial_code: '378',
    mobile_starts_with: ['3', '6'],
    phone_number_lengths: [10],
  },
  {
    name: 'Sao Tome and Principe',
    name_ar: 'ساو تومي وبرينسيب',
    code: 'ST',
    dial_code: '239',
    mobile_starts_with: ['98', '99'],
    phone_number_lengths: [7],
  },
  {
    name: 'Senegal',
    name_ar: 'السنغال',
    code: 'SN',
    dial_code: '221',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Serbia',
    name_ar: 'صربيا',
    code: 'RS',
    dial_code: '381',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8, 9],
  },
  {
    name: 'Seychelles',
    name_ar: 'سيشل',
    code: 'SC',
    dial_code: '248',
    mobile_starts_with: ['2'],
    phone_number_lengths: [7],
  },
  {
    name: 'Sierra Leone',
    name_ar: 'سيراليون',
    code: 'SL',
    dial_code: '232',
    mobile_starts_with: [
      '21',
      '25',
      '30',
      '33',
      '34',
      '40',
      '44',
      '50',
      '55',
      '76',
      '77',
      '78',
      '79',
      '88',
    ],
    phone_number_lengths: [8],
  },
  {
    name: 'Singapore',
    name_ar: 'سنغافورة',
    code: 'SG',
    dial_code: '65',
    mobile_starts_with: ['8', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Slovakia',
    name_ar: 'سلوفاكيا',
    code: 'SK',
    dial_code: '421',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Slovenia',
    name_ar: 'سلوفينيا',
    code: 'SI',
    dial_code: '386',
    mobile_starts_with: ['3', '4', '5', '6', '7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Solomon Islands',
    name_ar: 'جزر سليمان',
    code: 'SB',
    dial_code: '677',
    mobile_starts_with: ['7', '8'],
    phone_number_lengths: [7],
  },
  {
    name: 'Somalia',
    name_ar: 'الصومال',
    code: 'SO',
    dial_code: '252',
    mobile_starts_with: ['61', '62', '63', '65', '66', '68', '69', '71', '90'],
    phone_number_lengths: [9],
  },
  {
    name: 'South Africa',
    name_ar: 'جنوب أفريقيا',
    code: 'ZA',
    dial_code: '27',
    mobile_starts_with: ['1', '2', '3', '4', '5', '6', '7', '8'],
    phone_number_lengths: [9],
  },
  {
    name: 'South Sudan',
    name_ar: 'جنوب السودان',
    code: 'SS',
    dial_code: '211',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    name_ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    code: 'GS',
    dial_code: '500',
  },
  {
    name: 'Spain',
    name_ar: 'إسبانيا',
    code: 'ES',
    dial_code: '34',
    mobile_starts_with: ['6', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Sri Lanka',
    name_ar: 'سريلانكا',
    code: 'LK',
    dial_code: '94',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Sudan',
    name_ar: 'السودان',
    code: 'SD',
    dial_code: '249',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Suriname',
    name_ar: 'سورينام',
    code: 'SR',
    dial_code: '597',
    mobile_starts_with: ['6', '7', '8'],
    phone_number_lengths: [7],
  },
  {
    name: 'Svalbard and Jan Mayen',
    name_ar: 'سفالبارد ويان ماين',
    code: 'SJ',
    dial_code: '47',
    mobile_starts_with: ['79'],
    phone_number_lengths: [8],
  },
  {
    name: 'Swaziland',
    name_ar: 'إسواتيني',
    code: 'SZ',
    dial_code: '268',
  },
  {
    name: 'Sweden',
    name_ar: 'السويد',
    code: 'SE',
    dial_code: '46',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Switzerland',
    name_ar: 'سويسرا',
    code: 'CH',
    dial_code: '41',
    mobile_starts_with: ['74', '75', '76', '77', '78', '79'],
    phone_number_lengths: [9],
  },
  {
    name: 'Syrian Arab Republic',
    name_ar: 'سوريا',
    code: 'SY',
    dial_code: '963',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Taiwan',
    name_ar: 'تايوان',
    code: 'TW',
    dial_code: '886',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Tajikistan',
    name_ar: 'طاجيكستان',
    code: 'TJ',
    dial_code: '992',
    mobile_starts_with: ['9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    name_ar: 'تنزانيا',
    code: 'TZ',
    dial_code: '255',
    mobile_starts_with: ['7', '6'],
    phone_number_lengths: [9],
  },
  {
    name: 'Thailand',
    name_ar: 'تايلاند',
    code: 'TH',
    dial_code: '66',
    mobile_starts_with: ['6', '8', '9'],
    phone_number_lengths: [9],
  },
  {
    name: 'Timor-Leste',
    name_ar: 'تيمور الشرقية',
    code: 'TL',
    dial_code: '670',
    mobile_starts_with: ['7'],
    phone_number_lengths: [8],
  },
  {
    name: 'Togo',
    name_ar: 'توغو',
    code: 'TG',
    dial_code: '228',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Tokelau',
    name_ar: 'توكيلاو',
    code: 'TK',
    dial_code: '690',
    mobile_starts_with: [],
    phone_number_lengths: [4],
  },
  {
    name: 'Tonga',
    name_ar: 'تونغا',
    code: 'TO',
    dial_code: '676',
    mobile_starts_with: [],
    phone_number_lengths: [5],
  },
  {
    name: 'Trinidad and Tobago',
    name_ar: 'ترينيداد وتوباغو',
    code: 'TT',
    dial_code: '1868',
    mobile_starts_with: ['868'],
    phone_number_lengths: [10],
  },
  {
    name: 'Tunisia',
    name_ar: 'تونس',
    code: 'TN',
    dial_code: '216',
    mobile_starts_with: ['2', '4', '5', '9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Turkey',
    name_ar: 'تركيا',
    code: 'TR',
    dial_code: '90',
    mobile_starts_with: ['5'],
    phone_number_lengths: [10],
  },
  {
    name: 'Turkmenistan',
    name_ar: 'تركمانستان',
    code: 'TM',
    dial_code: '993',
    mobile_starts_with: ['6'],
    phone_number_lengths: [8],
  },
  {
    name: 'Turks and Caicos Islands',
    name_ar: 'جزر توركس وكايكوس',
    code: 'TC',
    dial_code: '1649',
    mobile_starts_with: ['6492', '6493', '6494'],
    phone_number_lengths: [10],
  },
  {
    name: 'Tuvalu',
    name_ar: 'توفالو',
    code: 'TV',
    dial_code: '688',
    mobile_starts_with: [],
    phone_number_lengths: [5],
  },
  {
    name: 'Uganda',
    name_ar: 'أوغندا',
    code: 'UG',
    dial_code: '256',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Ukraine',
    name_ar: 'أوكرانيا',
    code: 'UA',
    dial_code: '380',
    mobile_starts_with: ['39', '50', '63', '66', '67', '68', '73', '9'],
    phone_number_lengths: [9],
  },
  {
    name: 'United Arab Emirates',
    name_ar: 'الإمارات العربية المتحدة',
    code: 'AE',
    dial_code: '971',
    mobile_starts_with: ['5'],
    phone_number_lengths: [9],
  },
  {
    name: 'United Kingdom',
    name_ar: 'المملكة المتحدة',
    code: 'GB',
    dial_code: '44',
    mobile_starts_with: ['7'],
    phone_number_lengths: [10],
  },
  {
    name: 'United States',
    name_ar: 'الولايات المتحدة',
    code: 'US',
    dial_code: '1',
    mobile_starts_with: [
      '201',
      '202',
      '203',
      '205',
      '206',
      '207',
      '208',
      '209',
      '210',
      '212',
      '213',
      '214',
      '215',
      '216',
      '217',
      '218',
      '219',
      '220',
      '223',
      '224',
      '225',
      '227',
      '228',
      '229',
      '231',
      '234',
      '239',
      '240',
      '248',
      '251',
      '252',
      '253',
      '254',
      '256',
      '260',
      '262',
      '267',
      '269',
      '270',
      '272',
      '274',
      '276',
      '278',
      '281',
      '283',
      '301',
      '302',
      '303',
      '304',
      '305',
      '307',
      '308',
      '309',
      '310',
      '312',
      '313',
      '314',
      '315',
      '316',
      '317',
      '318',
      '319',
      '320',
      '321',
      '323',
      '325',
      '327',
      '330',
      '331',
      '332',
      '334',
      '336',
      '337',
      '339',
      '341',
      '346',
      '347',
      '351',
      '352',
      '360',
      '361',
      '364',
      '369',
      '380',
      '385',
      '386',
      '401',
      '402',
      '404',
      '405',
      '406',
      '407',
      '408',
      '409',
      '410',
      '412',
      '413',
      '414',
      '415',
      '417',
      '419',
      '423',
      '424',
      '425',
      '430',
      '432',
      '434',
      '435',
      '440',
      '441',
      '442',
      '443',
      '445',
      '447',
      '458',
      '463',
      '464',
      '469',
      '470',
      '475',
      '478',
      '479',
      '480',
      '484',
      '501',
      '502',
      '503',
      '504',
      '505',
      '507',
      '508',
      '509',
      '510',
      '512',
      '513',
      '515',
      '516',
      '517',
      '518',
      '520',
      '530',
      '531',
      '534',
      '539',
      '540',
      '541',
      '551',
      '557',
      '559',
      '561',
      '562',
      '563',
      '564',
      '567',
      '570',
      '571',
      '572',
      '573',
      '574',
      '575',
      '580',
      '582',
      '585',
      '586',
      '601',
      '602',
      '603',
      '605',
      '606',
      '607',
      '608',
      '609',
      '610',
      '612',
      '614',
      '615',
      '616',
      '617',
      '618',
      '619',
      '620',
      '623',
      '626',
      '627',
      '628',
      '629',
      '630',
      '631',
      '636',
      '640',
      '641',
      '646',
      '650',
      '651',
      '657',
      '659',
      '660',
      '661',
      '662',
      '667',
      '669',
      '678',
      '679',
      '680',
      '681',
      '682',
      '689',
      '701',
      '702',
      '703',
      '704',
      '706',
      '707',
      '708',
      '712',
      '713',
      '714',
      '715',
      '716',
      '717',
      '718',
      '719',
      '720',
      '724',
      '725',
      '726',
      '727',
      '730',
      '731',
      '732',
      '734',
      '737',
      '740',
      '743',
      '747',
      '752',
      '754',
      '757',
      '760',
      '762',
      '763',
      '764',
      '765',
      '769',
      '770',
      '772',
      '773',
      '774',
      '775',
      '779',
      '781',
      '785',
      '786',
      '787',
      '801',
      '802',
      '803',
      '804',
      '805',
      '806',
      '808',
      '810',
      '812',
      '813',
      '814',
      '815',
      '816',
      '817',
      '818',
      '820',
      '828',
      '830',
      '831',
      '832',
      '835',
      '838',
      '840',
      '843',
      '845',
      '847',
      '848',
      '850',
      '854',
      '856',
      '857',
      '858',
      '859',
      '860',
      '862',
      '863',
      '864',
      '865',
      '870',
      '872',
      '878',
      '901',
      '903',
      '904',
      '906',
      '907',
      '908',
      '909',
      '910',
      '912',
      '913',
      '914',
      '915',
      '916',
      '917',
      '918',
      '919',
      '920',
      '925',
      '927',
      '928',
      '929',
      '931',
      '934',
      '935',
      '936',
      '937',
      '938',
      '939',
      '940',
      '941',
      '945',
      '947',
      '949',
      '951',
      '952',
      '954',
      '956',
      '957',
      '959',
      '970',
      '971',
      '972',
      '973',
      '975',
      '978',
      '979',
      '980',
      '984',
      '985',
      '986',
      '989',
      '888',
      '800',
      '833',
      '844',
      '855',
      '866',
      '877',
      '279',
    ],
    phone_number_lengths: [10],
  },
  {
    name: 'Uruguay',
    name_ar: 'الأوروغواي',
    code: 'UY',
    dial_code: '598',
    mobile_starts_with: ['9'],
    phone_number_lengths: [8],
  },
  {
    name: 'Uzbekistan',
    name_ar: 'أوزبكستان',
    code: 'UZ',
    dial_code: '998',
    mobile_starts_with: ['9', '88', '33'],
    phone_number_lengths: [9],
  },
  {
    name: 'Vanuatu',
    name_ar: 'فانواتو',
    code: 'VU',
    dial_code: '678',
    mobile_starts_with: ['5', '7'],
    phone_number_lengths: [7],
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    name_ar: 'فنزويلا',
    code: 'VE',
    dial_code: '58',
    mobile_starts_with: ['4'],
    phone_number_lengths: [10],
  },
  {
    name: 'Vietnam',
    name_ar: 'فيتنام',
    code: 'VN',
    dial_code: '84',
    mobile_starts_with: ['8', '9', '3', '7', '5'],
    phone_number_lengths: [9],
  },
  {
    name: 'Virgin Islands, British',
    name_ar: 'جزر العذراء البريطانية',
    code: 'VG',
    dial_code: '1284',
    mobile_starts_with: ['284'],
    phone_number_lengths: [10],
  },
  {
    name: 'Virgin Islands, U.S.',
    name_ar: 'جزر العذراء الأمريكية',
    code: 'VI',
    dial_code: '1340',
    mobile_starts_with: ['340'],
    phone_number_lengths: [10],
  },
  {
    name: 'Wallis and Futuna',
    name_ar: 'واليس وفوتونا',
    code: 'WF',
    dial_code: '681',
    mobile_starts_with: [],
    phone_number_lengths: [6],
  },
  {
    name: 'Yemen',
    name_ar: 'اليمن',
    code: 'YE',
    dial_code: '967',
    mobile_starts_with: ['7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Zambia',
    name_ar: 'زامبيا',
    code: 'ZM',
    dial_code: '260',
    mobile_starts_with: ['9', '7'],
    phone_number_lengths: [9],
  },
  {
    name: 'Zimbabwe',
    name_ar: 'زيمبابوي',
    code: 'ZW',
    dial_code: '263',
    mobile_starts_with: ['71', '73', '77'],
    phone_number_lengths: [9],
  },
];

export const DEFAULT_COUNTRY = COUNTRIES.find((item) => item.code === 'SA');
