export const formatPrice = (price: number | string, maximumFractionDigits = 2): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(+price);
};

export const addVatToPrice = (original_price: number | string | undefined) => {
  return original_price ? formatPrice(+original_price * 1.15) : 0;
};

export const calculareDiscountPercentage = (originalPrice: number, price: number) => {
  return Math.round(Math.abs((originalPrice - price) / originalPrice) * 100);
};
