import Image from 'next/image';

const SponsorsList = () => {
  return (
    <div className='flex items-center justify-center py-12'>
      <div className='hide-scrollbar flex items-center justify-start gap-2 overflow-x-auto px-4'>
        <Image
          src='/images/sponsors/sprites.png'
          className='min-w-[1332px] shrink-0'
          width={1332}
          height={28}
          alt=''
        />
      </div>
    </div>
  );
};

export default SponsorsList;
