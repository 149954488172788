import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import { GLOBAL_REVALIDATE } from '../constants/revalidate';
import HomeBanner from '@/components/home/Banner';
import PredictSection from '@/components/predict';
import SponsorsList from '@/components/sponsors';

type PageProps = {
  /** */
};

const HomePage = ({}: PageProps) => {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title={t('common:title')} description={t('common:seo.description')} />

      <div className='h-0 opacity-0'>
        <h1>{t('common:seo.description')}</h1>
      </div>

      <HomeBanner />
      <PredictSection />

      <SponsorsList />
    </>
  );
};

export const getStaticProps: GetStaticProps<LayoutProps & PageProps> = async ({ locale = '' }) => {
  return {
    props: {
      footer: { type: 'mini' },
      ...(await serverSideTranslations(locale, ['common'])),
    },
    revalidate: GLOBAL_REVALIDATE,
  };
};

export default HomePage;
