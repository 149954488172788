import { useMemo, useState, createRef, useEffect, forwardRef, useRef } from 'react';
import { Combobox } from '@headlessui/react';
import Input from './Input';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { COUNTRIES } from '@/src/constants/countries';
import { Float } from '@headlessui-float/react';
import Image from 'next/image';

type Props = {
  label?: string | React.ReactNode;
  country: (typeof COUNTRIES)[number];
  mobile: string;
  handleChange: (name: string, value: string | (typeof COUNTRIES)[number]) => void;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  error?: string;
  required?: boolean;
  placeholder?: string;
};

const MobileInput = forwardRef<HTMLInputElement, Props>(function MobileInput(
  {
    label,
    country,
    mobile,
    handleChange,
    error,
    disabled,
    required,
    className,
    autoFocus,
    placeholder,
  },
  ref
) {
  const [query, setQuery] = useState('');
  const btnRef = useRef<HTMLButtonElement>(null);
  const mobileRef = createRef<HTMLInputElement>();
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  useEffect(() => {
    if (autoFocus && mobileRef.current) {
      mobileRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  const filteredCountries = useMemo(() => {
    return COUNTRIES.filter(
      (c) => c.dial_code.includes(query) || c.name.toLowerCase().includes(query.toLowerCase())
    );
  }, [query]);

  const selectedCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === country.code);
  }, [country.code]);

  const handleCountry = (value: (typeof COUNTRIES)[number]) => {
    handleChange('country', value);

    // Auto focus mobile
    mobileRef.current?.focus();
  };

  return (
    <fieldset disabled={disabled} className={className}>
      {label && <legend className='pb-1 font-semibold'>{label}</legend>}
      <Combobox value={selectedCountry} onChange={handleCountry} disabled={disabled}>
        <Float
          placement='bottom-start'
          as='div'
          adaptiveWidth
          // autoPlacement
          strategy='fixed'
          className='relative'
          floatingAs='div'
          portal
          tailwindcssOriginClass
          onHide={() => setQuery('')}
          enterFrom='opacity-0'
          enterTo='opacity-100 z-50'
          enter='transition'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='flex w-full items-baseline rtl:flex-row-reverse'>
            <div className='relative w-full max-w-[105px] shrink-0'>
              <label htmlFor='country_code' className='sr-only'>
                Country Code
              </label>
              <Combobox.Input
                dir='ltr'
                id='country_code'
                name='country_code'
                autoComplete='off'
                className='w-full rounded-l-lg border border-primary/50 bg-white/5 p-2 pl-8 text-white hover:bg-white/10 focus:border-primary focus:bg-white/10
                focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-white/10  disabled:text-white/20'
                displayValue={(country: (typeof COUNTRIES)[0]) => {
                  return `+${country.dial_code}`;
                }}
                onChange={(event) => {
                  setQuery(event.target.value.replace(/\+/g, ''));
                }}
                onClick={() => {
                  btnRef.current?.click();
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  // setOpen(true);
                  e.target.select();
                }}
              />
              <Combobox.Button
                className='absolute inset-y-0 left-0 flex items-center px-2 disabled:cursor-not-allowed'
                aria-label='Selected Country'
              >
                {selectedCountry && (
                  <Image
                    className='w-5 shrink-0'
                    src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${selectedCountry.code.toLowerCase()}.png`}
                    width={20}
                    height={13}
                    alt={selectedCountry.name}
                  />
                )}
              </Combobox.Button>
              <Combobox.Button
                className='absolute inset-y-0 right-0 flex items-center px-2 disabled:cursor-not-allowed'
                aria-label='Exapnd Country List'
                ref={btnRef}
              >
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  className='rotate-180 transform fill-gray-400 transition-transform'
                >
                  <path d='M6.70710678,15.7071081 C6.31658249,16.0976324 5.68341751,16.0976324 5.29289322,15.7071081 C4.90236893,15.3165838 4.90236893,14.6834188 5.29289322,14.2928945 L11.2928932,8.29289454 C11.6714722,7.9143156 12.2810586,7.90106998 12.6757246,8.26284718 L19.4313874,14.2942293 C19.8385064,14.6674217 19.8660094,15.2999891 19.4928169,15.7071081 C19.1196245,16.1142271 18.4870572,16.14173 18.0799382,15.7685376 L12.0300757,10.3841391 L6.70710678,15.7071081 Z'></path>
                </svg>
              </Combobox.Button>
            </div>
            <div className='z-10 flex-grow'>
              <Input
                className='rounded-r-md'
                dir='ltr'
                placeholder={placeholder}
                value={mobile}
                type='tel'
                name='mobile'
                onChange={(val) => {
                  const mobile = val.startsWith('0') ? val.substring(1) : val;
                  handleChange('mobile', mobile);
                }}
                error={error}
                ref={ref || mobileRef}
                autoFocus={autoFocus}
                required={required}
                minLength={selectedCountry?.phone_number_lengths?.[0]}
                maxLength={
                  selectedCountry?.phone_number_lengths
                    ? selectedCountry.phone_number_lengths.slice(-1)[0]
                    : undefined
                }
                rounded={false}
              />
            </div>
          </div>

          {/* Options */}
          <Combobox.Options
            className='mini-scrollbar -mt-5 max-h-80 w-full max-w-full overflow-auto rounded-md bg-zinc-800'
            unmount={false}
            data-testid='country-code-options'
          >
            {filteredCountries.length <= 0 && query !== '' ? (
              <p className='p-4 text-center text-white'>{t('common:no_results')}</p>
            ) : (
              filteredCountries.map((country) => (
                <Combobox.Option
                  key={country.name}
                  className={({ active }) =>
                    `relative flex cursor-pointer select-none items-center justify-between space-x-2 px-4 py-2
                        ${active ? 'bg-primary-light' : ''}`
                  }
                  data-dial-code={country.dial_code}
                  value={country}
                >
                  {({ selected }) => (
                    <div className='flex w-full items-center justify-between'>
                      <div className='flex w-full grow items-center gap-2'>
                        <Image
                          className='h-3 w-5 shrink-0'
                          src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${country.code.toLowerCase()}.png`}
                          width={20}
                          height={12}
                          alt={country.name}
                        />
                        <span
                          className={`block flex-1 truncate px-2
                        ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {country[`name_${locale}` as 'name'] || country.name}
                        </span>
                      </div>

                      <div className={selected ? 'font-medium' : 'font-normal'} dir='ltr'>
                        {`+${country.dial_code}`}
                      </div>
                    </div>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Float>
      </Combobox>
    </fieldset>
  );
});

export default MobileInput;
