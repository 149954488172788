import { Trans, useTranslation } from 'next-i18next';
import MainButton from '../buttons/MainButton';
import HowToParticipate from './HowTo';
import PredictionSubmit from './Submit';
import { scrollIntoElementId } from '@/util/scrollIntoView';
import Image from 'next/image';

const PredictSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className='container space-y-8 py-12'>
        <div className='mx-auto max-w-lg space-y-4 text-center'>
          <Image
            className='mx-auto shrink-0'
            src='/icons/common/achievement_red.png'
            width={65}
            height={95}
            alt=''
          />
          <h2 className='text-balance uppercase text-secondary'>
            <Trans i18nKey='predict.title' ns='common'>
              <span className='text-primary underline underline-offset-8'></span>
            </Trans>
          </h2>
          <p className='rtl:font-lama'>{t('common:predict.description')}</p>
        </div>

        <div className='mx-auto flex max-w-lg items-center justify-center gap-4'>
          <MainButton
            className='text-xl rtl:font-lama'
            theme='secondary'
            shape='text'
            onClick={() => {
              scrollIntoElementId('make_prediction');
            }}
          >
            {t('common:predict.make_guess')}
          </MainButton>
        </div>
      </section>

      <section className='container mx-auto flex max-w-7xl flex-col gap-14 py-12 lg:flex-row'>
        <HowToParticipate />
        <PredictionSubmit />
      </section>
    </>
  );
};

export default PredictSection;
